










































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import store from '../../../store';

@Component({
})
export default class Pangenation extends Vue {
  @Prop({ default: null })
  private paramsName!: number; // 現在のページ番号
  @Prop({ default: null })
  private total!: any; // コースの総数
  @Prop({ default: null })
  private link!: string; // リンク先
  @Prop({ default: 5 })
  private btnMaxNum!: number; // ボタンの総数
  @Prop({ default: 5 })
  private displayMaxNum!: number; // ページ内に表示するコースの最大数

  get btnAroundNum(): number {
    return (this.btnMaxNum - 1) / 2;
  } // ボタンの前後の数 2

  get btnArrowIsShowPc(): boolean {
    return (this.total > (this.btnMaxNum * 5)) ? true : false;
  }

  get btnArrowIsShowSp(): boolean {
    return (this.total > (this.btnMaxNum * 3)) ? true : false;
  }

  get btnFirst(): number {
    let result = this.paramsName - Math.floor(this.btnAroundNum);
    if (this.pageMaxNum <= this.btnMaxNum) {
      result = 1;
    } else if (this.paramsName <= 1 + Math.floor(this.btnAroundNum)) {
      result = 1;
    } else if (
        this.paramsName >= Math.ceil(this.btnAroundNum)
        && this.paramsName + Math.ceil(this.btnAroundNum) < this.pageMaxNum
      ) {
      result = this.paramsName - Math.ceil(this.btnAroundNum);
    } else if (this.paramsName + Math.ceil(this.btnAroundNum) >= this.pageMaxNum) {
      result = this.pageMaxNum - this.btnMaxNum + 1;
    }
    return result;
  }
  get btnLast(): number {
    let result = this.paramsName + Math.ceil(this.btnAroundNum);
    if (this.pageMaxNum <= this.btnMaxNum) {
      result = this.pageMaxNum;
    } else if (this.paramsName <= 1 + Math.floor(this.btnAroundNum)) {
      result = this.btnMaxNum;
    } else if (
        this.paramsName >= Math.ceil(this.btnAroundNum)
        && this.paramsName + Math.ceil(this.btnAroundNum) < this.pageMaxNum
      ) {
      result = this.paramsName + Math.ceil(this.btnAroundNum);
    } else if (this.paramsName + Math.ceil(this.btnAroundNum) >= this.pageMaxNum) {
      result = this.pageMaxNum;
    }
    return result;
  }

  get pages(): number[] {
    const result = [];
    for (let i = this.btnFirst; i <= this.btnLast; i++) {
      result.push(i);
    }
    return result;
  }

  @Emit()
  private isHiddenBtn(num: number): boolean {
    let result = true;
    const small = this.btnMaxNum - this.btnAroundNum;
    const large = this.pageMaxNum - this.btnAroundNum;
    if (this.paramsName < small) {
      if (num <= small) {
        result = false;
      }
    } else if (this.paramsName <= small || large >= this.paramsName) {
      if (
          num === this.paramsName
          || num === this.prevNum
          || num === this.nextNum
        ) {
        result = false;
      }
    } else {
      if (num >= large) {
        result = false;
      }
    }
    return result;
  }


  get pageMaxNum(): number {
    return Math.ceil(this.total / this.displayMaxNum);
  }
  get prevNum(): number {
    return this.paramsName - 1;
  }
  get nextNum(): number {
    return this.paramsName + 1;
  }

  get prev(): boolean {
    let result = true;
    if (!this.prevNum) {
      result = false;
    }
    return result;
  }

  get next(): boolean {
    let result = true;
    if (this.pageMaxNum < this.nextNum) {
      result = false;
    }
    return result;
  }
}
